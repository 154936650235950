<template>
  <div class="actions">
    <div
      class="btn"
      @click="$emit('showQr')"
    >
      <QrCodeIcon
        class="supel"
      />
      <span>{{ $t("qr_code") }}</span>
    </div>

    <div
      class="btn"
      @click="$emit('report')"
    >
      <FlagIcon
        class="supel"
      />
      <span>{{ $t("report.report") }}</span>
    </div>

    <div
      class="btn"
      @click="block"
    >
      <BlockIcon
        class="supel"
      />
      <span v-if="blacklisted">{{ $t("unblock") }}</span>
      <span v-else>{{ $t("block") }}</span>
    </div>
  </div>
</template>

<script>
import BlockIcon from '@/components/icons/Block.vue';
import FlagIcon from '@/components/icons/Flag.vue';
import QrCodeIcon from '@/components/icons/QrCode.vue';

export default {
  components: {
    BlockIcon,
    FlagIcon,
    QrCodeIcon,
  },
  props: {
    blacklisted: Boolean,
  },
  methods: {
    block() {
      if (this.blacklisted) {
        this.$emit('unBlock');
      } else {
        this.$emit('block');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  margin: 0 auto 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  white-space: nowrap;

  .btn {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 32px;
    width: 32px;

    .supel {
      width: 32px;
      height: 32px;
      padding: 6px;
      box-sizing: border-box;
      background-color: $clr-20;
      color: $clr-text-primary;
    }

    span {
      margin-top: 6px;
      font: $font-rn-200;
      color: $clr-text-secondary;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .actions {
    margin: 32px auto 0 auto;
    padding: 0 16px;
  }
}
</style>
