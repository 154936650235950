<template>
  <FeedWrapper
    :has-items="channels.length !== 0"
    :all-loaded="allLoaded"
    class="channel-feed"
  >
    <ChannelComponent
      v-for="channel in channels"
      :key="channel.id"
      :channel="channel"
    />
  </FeedWrapper>
</template>

<script>
import { mapActions } from 'vuex';

import FeedWrapper from '../FeedWrapper.vue';

import ChannelComponent from './Channel.vue';

import channelFromBackend from '@/models/dto/channelFromBackend';

export default {
  name: 'ChannelFeed',
  components: {
    ChannelComponent,
    FeedWrapper,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      channels: [],
      allLoaded: false,
    };
  },
  watch: {
    userId: {
      async handler() {
        this.channels = (await this.getUserChannels({
          userId: this.userId,
          admin: true,
        }))
          .map((channel) => channelFromBackend(channel));

        this.allLoaded = true;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getUserChannels']),
  },
};
</script>

<style lang="scss" scoped>
.channel-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $clr-border-middle;
}
</style>
