<template>
  <div
    class="code"
    :class="{ active }"
  >
    <Logo :sn="code.sn" />
    <div class="main">
      <div
        class="value"
        @click="copy"
      >
        <PromocodeIcon class="promocode" />
        <span ref="value">
          {{ code.value }}
        </span>
        <ClipboardIcon
          v-if="active"
          class="copy"
        />
      </div>

      <div class="dates">
        {{ formatDate(code.createdAt) }} - {{ formatDate(code.archivedAt) }}
      </div>
    </div>

    <div class="count">
      <GroupIcon />
      {{ code.inviteeCount }}
    </div>

    <button
      v-if="active"
      class="archive"
      @click="archive"
    >
      <ArchiveIcon />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import formatDateTimeMixin from '@/mixins/formatDateTimeMixin';

import ArchiveIcon from '@/components/icons/Archive.vue';
import ClipboardIcon from '@/components/icons/Clipboard.vue';
import GroupIcon from '@/components/icons/Group.vue';
import PromocodeIcon from '@/components/icons/Promocode.vue';

import Logo from '@/components/Logo.vue';

export default {
  emits: ['archive'],
  components: {
    PromocodeIcon,
    GroupIcon,
    ArchiveIcon,
    ClipboardIcon,
    Logo,
  },
  mixins: [formatDateTimeMixin],
  props: {
    code: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['archiveInviteCode']),
    formatDate(date) {
      if (date) {
        return this.formatDateTime(date);
      }
      return '...';
    },
    archive() {
      this.archiveInviteCode(this.code.id);
      this.$nextTick(() => this.$emit('archive'));
    },
    copy() {
      const el = this.$refs.value;
      this.$copyText(this.code.value, el);
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(8, 11, 48, 0.2);
  margin: 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  .sn-avatar {
    border-radius: 4px;
    width: 48px;
    height: 48px;
  }

  .main {
    display: flex;
    flex-direction: column;
  }

  .value {
    font: $font-sbw-400;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    width: max-content;

    svg {
      color: $clr-text-secondary;
      margin-right: 8px;
    }

    .copy {
      width: 16px;
      height: 16px;
    }
  }

  .dates {
    color: $clr-text-secondary;
    font: $font-rn-300;
  }

  .count {
    position: absolute;
    top: 8px;
    right: 16px;
    display: flex;
    align-items: center;
    font: $font-sbw-400;

    svg {
      margin-right: 8px;
    }
  }

  .archive {
    position: absolute;
    bottom: 8px;
    right: 16px;
    background: white;
    border: 1px solid $clr-border-light;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;

    svg {
      width: 18px;
    }
  }

  &.active {
    box-shadow: none;

    .value {
      cursor: pointer;

      svg.promocode {
        color: var(--clr-brand);
      }
    }
  }
}
</style>
