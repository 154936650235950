<template>
  <router-link
    v-if="channel.admin"
    class="btn secondary outline"
    :to="getLinkToChat(channel)"
  >
    {{ $t('messenger.my_channel') }}
  </router-link>

  <button
    v-else-if="channel.subscriber"
    class="btn secondary"
    @click="unsubscribe"
  >
    {{ $t('subscribed') }}
  </button>

  <button
    v-else
    class="btn primary"
    @click="subscribe"
  >
    {{ $t('subscribe') }}
  </button>
</template>

<script>
import { mapActions } from 'vuex';

import linkToChatMixin from '@/mixins/linkToChatMixin';

import Channel from '@/models/Channel';

export default {
  mixins: [linkToChatMixin],
  props: {
    channel: {
      type: Channel,
      required: true,
    },
  },
  methods: {
    ...mapActions(['joinChannel', 'deleteChat']),
    subscribe() {
      this.joinChannel({ id: this.channel.id });
      this.channel.subscriber = true;
    },
    unsubscribe() {
      this.deleteChat(this.channel.longId);
      this.channel.subscriber = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  height: fit-content;
}
</style>
