<template>
  <PostOrVideoFeed
    :user-id="userId"
    type="video"
  >
    <template v-slot="item">
      <LongVideo :untyped-item="item" />
    </template>
  </PostOrVideoFeed>
</template>

<script>
import LongVideo from './LongVideo.vue';
import PostOrVideoFeed from './PostOrVideoFeed.vue';

export default {
  components: {
    PostOrVideoFeed,
    LongVideo,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
};
</script>
