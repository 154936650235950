<template>
  <div class="channel">
    <router-link
      class="channel-info"
      :to="getLinkToChat(channel)"
    >
      <img
        :src="channel.avatar"
        class="avatar"
      >
      <div class="text">
        <h3 class="name">
          {{ channel.name }}
        </h3>
        <div class="username">
          @{{ channel.username }}
        </div>
        <div class="subs">
          {{ $t('subscribers') }}: {{ channel.subscriberCount }}
        </div>
      </div>
    </router-link>

    <SubscribeButton
      :channel="channel"
    />
  </div>
</template>

<script>
import linkToChatMixin from '@/mixins/linkToChatMixin';

import SubscribeButton from './SubscribeButton.vue';

import Channel from '@/models/Channel';

export default {
  components: {
    SubscribeButton,
  },
  mixins: [linkToChatMixin],
  props: {
    channel: {
      type: Channel,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.channel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid $clr-border-middle;

  .channel-info {
    display: flex;

    .avatar {
      min-width: 56px;
      width: 56px;
      height: 56px;
      border-radius: 12px;
      margin-right: 16px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font: $font-sbn-400;
      }

      .username, .subs {
        color: $clr-text-secondary;
      }
    }
  }
}
</style>
