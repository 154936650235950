<template>
  <div
    v-if="hasItems"
    class="feed"
  >
    <slot />
  </div>
  <div
    v-else-if="allLoaded"
    class="all-watched"
  >
    {{ $t("all_watched") }}
  </div>
  <div
    v-else
    class="spinner"
  >
    <SpinnerIcon />
  </div>
</template>

<script>
import SpinnerIcon from '@/components/icons/Spinner.vue';

export default {
  components: {
    SpinnerIcon,
  },
  props: {
    hasItems: {
      type: Boolean,
    },
    allLoaded: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.feed {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 120px;
  background-color: $clr-05;
}

.spinner, .all-watched {
  text-align: center;
  padding-top: 96px;

  svg {
    color: $clr-text-trietary;
    width: 39px;
    height: 39px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .feed {
    gap: 1px;
  }
}
</style>
