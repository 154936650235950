<template>
  <div class="promocodes">
    <div class="top">
      <div class="without-sidebar">
        <router-link
          class="back btn"
          :to="{ name: 'Profile' }"
        >
          <ArrowIcon />
          <span>
            {{ $t('back') }}
          </span>
        </router-link>

        <div class="all-time-invites">
          {{ $t('profile.all_time') }} <GroupIcon /> <b>{{ totalInvites }}</b>
        </div>

        <div v-if="activeCode">
          <h2>{{ $t('profile.active_promocode') }}</h2>

          <Code
            :code="activeCode"
            active
            @archive="moveToArchive"
          />
        </div>
        <button
          v-else
          class="create-promocode btn primary"
          @click="$refs.createPromocodeModal.open()"
        >
          {{ $t('profile.create_promocode') }}
        </button>
      </div>
    </div>

    <div
      v-if="archivedCodes.length > 0"
      class="archived without-sidebar"
    >
      <h2>{{ $t('archive') }}</h2>

      <Code
        v-for="code in archivedCodes"
        :key="code.id"
        :code="code"
      />
    </div>

    <CreatePromocodeModal
      ref="createPromocodeModal"
      @newCode="getCodes"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import ArrowIcon from '@/components/icons/Arrow.vue';
import GroupIcon from '@/components/icons/Group.vue';

import Code from './Code.vue';
import CreatePromocodeModal from './CreatePromocodeModal.vue';

export default {
  name: 'Promocodes',
  components: {
    Code,
    CreatePromocodeModal,
    ArrowIcon,
    GroupIcon,
  },
  data() {
    return {
      codes: [],
    };
  },
  computed: {
    archivedCodes() {
      return this.codes.filter((code) => code.archivedAt);
    },
    activeCode() {
      return this.codes.filter((code) => !code.archivedAt)[0];
    },
    totalInvites() {
      return this.codes.reduce((total, code) => total + code.inviteeCount, 0);
    },
  },
  mounted() {
    this.getCodes();
  },
  methods: {
    ...mapActions(['getInviteCodes', 'createInviteCode']),
    async getCodes() {
      this.codes = await this.getInviteCodes();
    },
    moveToArchive() {
      this.codes.map((c) => {
        const code = c;
        if (code.id === this.activeCode.id) {
          code.archivedAt = new Date();
        }

        return code;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promocodes {
  text-align: center;
  padding-top: 0 !important;

  .top {
    background: white;
    top: 0;
    left: 0;
    padding: 96px 0 24px 0;
    box-shadow: 0px 2px 8px rgba(8, 11, 48, 0.2);

    .back {
      display: flex;
      align-items: center;
      color: $clr-text-secondary;
      margin-bottom: 32px;

      svg {
        color: inherit;
        margin-right: 8px;
      }
    }

    .all-time-invites {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $clr-05;
      width: max-content;
      border-radius: 12px;
      padding: 2px 16px;
      margin: auto;

      svg {
        margin: 8px;
      }

      b {
        font: $font-sbw-400;
      }
    }

    .create-promocode {
      margin: 32px auto 0;
    }
  }

  h2 {
    margin: 32px 0 16px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .promocodes {
    .top {
      padding-top: 96px;

      .back {
        background: white;
        border: 1px solid $clr-border-light;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        position: absolute;
        top: 30px;
        left: 30px;

        svg {
          margin: 0;
        }

        span {
          display: none;
        }
      }
    }
  }
}
</style>
