<template>
  <img
    class="logo"
    :src="logo"
  >
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    sn: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    ...mapState({
      selectedSn: (state) => state.api.sn.selectedSn,
    }),
    logo() {
      return this.sn ? this.sn.avatar : this.selectedSn.avatar;
    },
  },
};
</script>

<style scoped>
.logo {
  height: 40px;
  border-radius: 8px;
}
</style>
