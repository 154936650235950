<template>
  <div class="categories">
    <div
      v-for="category in categories"
      :key="category.name"
      class="category"
      :class="{
        active: category.name === selectedCategory,
        soon: category.disabled
      }"
      @click="!category.disabled && selectCategory(category)"
    >
      <component :is="category.icon" />
      <span>{{ $t(category.name) }}</span>
    </div>
  </div>
</template>

<script>
import ChannelsIcon from '@/components/icons/Channels.vue';
import RoomIcon from '@/components/icons/Room.vue';
import StreamsIcon from '@/components/icons/Streams.vue';
import VideoIcon from '@/components/icons/Video.vue';

export default {
  emits: ['selectCategory'],
  components: {
    RoomIcon,
    VideoIcon,
    StreamsIcon,
    ChannelsIcon,
  },
  data() {
    return {
      selectedCategory: 'posts',
      categories: [
        {
          component: 'PostFeed',
          icon: 'RoomIcon',
          name: 'posts',
        },
        {
          component: 'VideoFeed',
          icon: 'VideoIcon',
          name: 'videos',
        },
        {
          component: 'StreamFeed',
          icon: 'StreamsIcon',
          name: 'streams',
          disabled: true,
        },
        {
          component: 'ChannelFeed',
          icon: 'ChannelsIcon',
          name: 'channels',
        },
      ],
    };
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category.name;
      this.$emit('selectCategory', category.component);
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  margin: 32px 8px 0 8px;
  border-top: solid $clr-border-light 1px;
  display: flex;
  justify-content: space-between;

  .category {
    padding: 20px 0;
    border-top: solid transparent 1px;
    position: relative;
    top: -1px;
    font: $font-sbw-300;
    display: flex;
    align-items: center;
    cursor: pointer;

    .active {
      color: var(--clr-brand);
    }

    svg {
      color: $clr-text-primary;
      margin-right: 10px;
    }

    &.soon {
      cursor: default;

      svg, span {
        color: $clr-text-trietary;
      }

      ::after {
        position: absolute;
        content: 'soon';
        font: $font-rn-200;
        bottom: 10%;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  .active {
    border-top: solid var(--clr-brand) 1px;

    svg, span {
      color: var(--clr-brand);
    }
  }
}
</style>
