<template>
  <div class="item">
    <router-link
      :to="item.link"
      class="video-wrapper"
      @mouseover.native="playVid"
      @mouseleave.native="pauseVid"
    >
      <video
        ref="video"
        :src="item.video"
      />
      <div class="playbtn" />
    </router-link>
    <div class="bar">
      <div class="counters">
        <ViewCounter
          :post="item"
        />
        <LikeCounter
          :post="item"
          class="btn"
        />
        <CommentCounter
          :post="item"
          class="btn"
        />
      </div>
      <router-link
        :to="item.link"
        class="title"
      >
        {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
import CommentCounter from '@/components/CommentCounter.vue';
import LikeCounter from '@/components/LikeCounter.vue';
import ViewCounter from '@/components/ViewCounter.vue';

import VideoRound from '@/models/VideoRound';

export default {
  components: {
    ViewCounter,
    LikeCounter,
    CommentCounter,
  },
  props: {
    untypedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playPromise: undefined,
    };
  },
  computed: {
    item() {
      return new VideoRound(this.untypedItem);
    },
  },
  methods: {
    playVid() {
      this.playPromise = this.$refs.video.play();
    },
    pauseVid() {
      if (this.playPromise !== undefined) {
        this.playPromise.then(() => {
          this.$refs.video.pause();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  border-radius: 24px;
  margin: 0 8px 64px 8px;
  position: relative;
  overflow: hidden;
  box-shadow: $shadow-3;

  .video-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    background: $clr-40;

    video {
      display: block;
      width: 100%;
      height: 100%;
    }

    .playbtn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: no-repeat center url("~@/assets/icons/play-white.svg"),
        no-repeat center url("~@/assets/icons/transbg.svg");

      &:hover {
        opacity: 0;
      }
    }
  }

  .bar {
    padding: 16px;
    background: $clr-05;
    display: flex;
    justify-content: space-between;

    .counters {
      display: flex;
      flex-shrink: 0;

      > * {
        margin-right: 16px;
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font: $font-sbn-400;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .item {
    margin: 0 8px 32px 8px;
  }
}

@media screen and (max-width: 600px) {
  .item > .bar > .title {
    display: none;
  }
}
</style>
