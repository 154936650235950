<template>
  <Modal
    ref="modal"
  >
    <h1>
      {{ title }}
    </h1>
    <div class="list">
      <div
        v-for="user in list"
        :key="user.id"
        class="row"
      >
        <MiniEntity
          :entity="user"
          :link="user.link"
        />
        <FollowButton
          :id="user.id"
          :is-following="user.isFollowing"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import FollowButton from '@/components/FollowButton.vue';
import MiniEntity from '@/components/MiniEntity.vue';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
    MiniEntity,
    FollowButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    $route() {
      this.close();
    },
  },
  methods: {
    open(list) {
      this.list = list;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-transform: capitalize;
  margin-bottom: 32px;
}

.list {
  position: relative;
  max-width: 100%;
  max-height: 400px;
  overflow: auto;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .entity {
      min-width: 0;
    }
  }
}
</style>
