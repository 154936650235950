<template>
  <router-link
    :to="{ name: 'Round', params: { id: item.id } }"
    class="item"
  >
    <component
      :is="mediaComponent"
      :item="item"
    />
    <ViewCounter
      class="views"
      :post="item"
    />
  </router-link>
</template>

<script>

import ViewCounter from '@/components/ViewCounter.vue';

import PhotoPostMedia from './PhotoPostMedia.vue';
import VideoPostMedia from './VideoPostMedia.vue';

import roundFactory from '@/models/roundFactory';

export default {
  components: {
    ViewCounter,
  },
  props: {
    untypedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playPromise: undefined,
      item: roundFactory(this.untypedItem),
      mediaComponent: this.untypedItem.video ? VideoPostMedia : PhotoPostMedia,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: calc(33.3% - 8px);
  margin: 4px;
  height: 0;
  padding-top: 56.25%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .views {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    font: $font-sbw-300;
    color: white;
    filter: $visibility-shadow;

    svg {
      color: white;
      margin-right: 8.75px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .item {
    width: calc(33.3% - 1px);
    margin: 0;
    border-radius: 0;

    .views {
      bottom: 6px;
      left: 6px;
    }
  }
}
</style>
