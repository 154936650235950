<template>
  <div class="person">
    <Avatar
      :my-profile="ownProfile"
      :avatar="profile.avatar"
    />
    <div class="right">
      <div class="name">
        {{ profile.name }}
      </div>
      <div class="username">
        @{{ profile.username }}
        <VerifiedCheckIcon
          v-if="profile.verified"
        />
      </div>

      <div
        v-if="ownProfile"
        class="buttons"
      >
        <div class="flex">
          <router-link
            class="btn"
            :to="{ name: 'EditProfile' }"
          >
            <EditIcon />{{ $t("profile.edit") }}
          </router-link>
          <label
            class="btn change-avatar"
            for="avatar-upload"
          >
            <ExpandIcon class="frame" />
            <ProfileIcon class="person" />
          </label>
        </div>

        <!-- <router-link
          class="btn"
          to=""
        >
          <CrownIcon />{{ $t("profile.buy_vip") }}
        </router-link> -->

        <span class="promocode-and-settings">
          <span
            v-if="profile.isInfluencer"
            class="influencer-block"
          >
            <router-link
              class="btn promocode"
              :to="{ name: 'Promocodes' }"
              @click="$refs.promocodesModal.open()"
            >
              <PromocodeIcon />
              <!-- {{ $t("profile.promocodes") }} -->
            </router-link>
          </span>

          <router-link
            class="btn settings"
            :to="{ name: 'Settings' }"
          >
            <SettingsIcon />{{ $t("settings.settings") }}
          </router-link>
        </span>
      </div>
      <div
        v-else
        class="buttons"
      >
        <FollowButton
          :id="Number(profile.id)"
          :is-following="profile.isFollowing"
          @update="(status) => (profile.isFollowing = status)"
        />
        <router-link
          class="btn message"
          :to="dmLink"
        >
          <MessengerIcon />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// import CrownIcon from '@/components/icons/CrownFilled.vue';

import linkToChatMixin from '@/mixins/linkToChatMixin';

import EditIcon from '@/components/icons/Edit.vue';
import ExpandIcon from '@/components/icons/Expand.vue';
import MessengerIcon from '@/components/icons/Messenger.vue';
import ProfileIcon from '@/components/icons/Profile.vue';
import PromocodeIcon from '@/components/icons/Promocode.vue';
import SettingsIcon from '@/components/icons/Settings.vue';
import VerifiedCheckIcon from '@/components/icons/VerifiedCheck.vue';

import FollowButton from '@/components/FollowButton.vue';

import Avatar from './Avatar.vue';

import Dm from '@/models/Dm';
import User from '@/models/User';

export default {
  name: 'Person',
  components: {
    FollowButton,
    Avatar,
    // CrownIcon,
    EditIcon,
    MessengerIcon,
    ExpandIcon,
    ProfileIcon,
    PromocodeIcon,
    SettingsIcon,
    VerifiedCheckIcon,
  },
  mixins: [linkToChatMixin],
  props: {
    profile: {
      type: User,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      stateProfile: 'profile',
    }),
    ownProfile() {
      if (this.profile && this.stateProfile) {
        return this.profile.id === this.stateProfile.id;
      }
      return false;
    },
    dmLink() {
      if (this.profile.id === 1924) {
        return {
          name: 'Messenger',
          params: {
            chatId: `support_${this.stateProfile.id}`,
            chatStringified: JSON.stringify(this.profile),
          },
        };
      }

      const chat = new Dm({ id: this.profile.id });
      chat.partner = this.profile;
      return this.getLinkToChat(chat);
    },
  },
};
</script>

<style lang="scss" scoped>
.person {
  display: flex;

  .right {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .name {
      font: $font-h-500;
    }

    .username {
      display: flex;
      align-items: center;
      font: $font-rn-400;
      color: $clr-text-secondary;

      svg {
        height: 20px;
        margin-left: 6px;
        color: var(--clr-brand);
      }
    }

    .buttons {
      margin-top: 16px;
      display: flex;

      .btn {
        background: white;
        font: $font-sbw-300;
        padding: 5px 16px;
        border-radius: 12px;
        border: 1px solid $clr-border-light;
        margin-right: 8px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 9px;
          color: $clr-text-trietary;
        }

        &.message {
          padding: 5px;

          svg {
            margin-right: 0;
            padding: 1px;
            box-sizing: border-box;
          }
        }
      }

      .promocode {
        padding: 6px;

        svg {
          margin: 0;
        }
      }

      .promocode-and-settings {
        display: flex;
      }

      .settings {
        display: none;
      }

      .change-avatar {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .person {
    flex-direction: column;
    align-items: center;

    .right {
      margin: 24px 0 0 0;
      align-items: center;

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
          width: fit-content;
          margin-right: 0 !important;
          margin-bottom: 8px;
        }

        .change-avatar, .promocode, .settings {
          display: flex;
          justify-content: center;
          align-items: center;

          .frame {
            width: 24px;
            height: 24px;
          }

          .person {
            width: 18px;
            height: 18px;
          }
        }

        .settings {
          height: fit-content;
          margin: 0;
        }

        .change-avatar {
          svg {
            margin: 0;
            position: absolute;
          }
        }
      }
    }
  }
}
</style>
