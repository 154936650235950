<template>
  <div
    class="qr"
    style="overflow: hidden"
  >
    <!-- <div class="qr-text">
      {{ $t("qr_text") }}
    </div> -->
    <div class="qr-image-wrapper">
      <div
        class="qr-image"
        :style="{ backgroundImage: 'url(' + imageCode + ')' }"
      >
        <!-- <div class="qr-image-overlay">
          <a download="qr.png" :href="imageCode">{{ $t("download") }}</a>
        </div> -->
      </div>
    </div>
    <!-- <div class="qr-text">{{ fullURI }}</div> -->
  </div>
</template>

<script>
import QrCodeWithLogo from 'qr-code-with-logo';
import { mapState } from 'vuex';

export default {
  props: {
    uri: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      imageCode: '',
    };
  },
  computed: {
    ...mapState({
      defaultSn: (state) => state.api.sn.defaultSn,
    }),
  },
  watch: {
    uri() {
      this.createQr();
    },
  },
  mounted() {
    this.createQr();
  },
  methods: {
    createQr() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const nodeQrCodeOptions = {
        margin: 0,
        color: {
          dark: '#00000000',
          light: '#fff',
        },
        errorCorrectionLevel: 'H',
      };
      const logo = {
        src: this.defaultSn.avatar,
        borderRadius: 0,
        logoSize: 0.364,
        bgColor: '#fff',
        borderColor: 'red',
        borderSize: 0.046,
      };

      canvas.width = 1000;
      canvas.height = 1000;
      canvas.style.top = '-9999999px';
      canvas.style.left = '-9999999px';
      canvas.style.position = 'absolute';
      canvas.style.pointerEvents = 'none';
      canvas.style.visibility = 'hidden';
      canvas.style.imageRendering = 'pixelated';
      canvas.style.imageRendering = 'crisp-edges';
      ctx.rect(0, 0, 1000, 1000);
      ctx.fillStyle = '#fff';
      ctx.fill();

      document.body.appendChild(canvas);

      QrCodeWithLogo.toCanvas({
        canvas,
        content: this.uri,
        width: 500,
        nodeQrCodeOptions,
        // logo,
      }).then(() => {
        this.$nextTick(() => {
          this.imageCode = canvas.toDataURL();
          canvas.remove();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.qr {
  display: flex;
  justify-content: center;
}
.qr-text {
  font-size: 0.8rem;
  line-height: 1.5;
}
.qr-image-wrapper {
  margin: 50px 0;
  background: $grad-brand;
  width: fit-content;
  height: 254px;
}
.qr-image {
  margin: auto;
  width: 254px;
  height: 254px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.qr-image-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  bottom: 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}
.qr-image:hover .qr-image-overlay {
  opacity: 1;
}
</style>
