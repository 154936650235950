<template>
  <div>
    <div
      class="counter"
      @click="$refs.commentsModal.open()"
    >
      <CommentIcon />&nbsp;
      {{ $formatNum(commentCount) }}
    </div>

    <Modal ref="commentsModal">
      <Comments
        :round="post"
        @commentAdded="commentCount += 1"
      />
    </Modal>
  </div>
</template>

<script>
import CommentIcon from '@/components/icons/Comment.vue';

import Comments from '@/components/Comments/Index.vue';
import Modal from '@/components/Modal.vue';

import Round from '@/models/Round';

export default {
  components: {
    CommentIcon,
    Modal,
    Comments,
  },
  props: {
    post: {
      type: Round,
      required: true,
    },
  },
  data() {
    return {
      commentCount: this.post.commentCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
