<template>
  <PostOrVideoFeed
    :user-id="userId"
    type="post"
  >
    <template v-slot="item">
      <Post :untyped-item="item" />
    </template>
  </PostOrVideoFeed>
</template>

<script>
import Post from './Post.vue';
import PostOrVideoFeed from './PostOrVideoFeed.vue';

export default {
  components: {
    PostOrVideoFeed,
    Post,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
};
</script>
