<template>
  <Modal
    ref="modal"
  >
    <div>
      <h1>{{ $t("profile.new_avatar") }}</h1>

      <VueCropper
        ref="cropper"
        :src="previewSrc"
        class="preview"
      />
      <div class="buttons">
        <button
          class="btn secondary"
          @click="close"
        >
          {{ $t("cancel") }}
        </button>
        <button
          class="btn primary"
          @click="confirm"
        >
          {{ $t("confirm") }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import { mapActions } from 'vuex';

import Modal from '@/components/Modal.vue';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    Modal,
    VueCropper,
  },
  data() {
    return {
      file: undefined,
      croppedImg: undefined,
      previewSrc: '',
    };
  },
  methods: {
    ...mapActions(['changeAvatar']),
    open(file) {
      this.$refs.modal.open();
      this.file = file;
      this.previewSrc = URL.createObjectURL(file);
    },
    close() {
      this.$refs.modal.close();
    },
    confirm() {
      this.$refs.cropper.getCroppedCanvas()
        .toBlob((blob) => {
          const file = new File([blob], 'avatar.png', { type: this.file.type });
          this.changeAvatar(file);
        });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding-bottom: 16px;
}

.preview {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 32px 0 0 0;
}
</style>
