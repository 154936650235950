<template>
  <div class="about">
    <!-- eslint-disable -->
    <!-- Вроде как vue-highlights защищен от xss -->
    <div
      class="status"
      v-html="highlightedStatus"
    />
    <!-- eslint-enable -->
    <div
      v-if="profile.locationName"
      class="location"
    >
      <MapPinIcon />
      {{ profile.locationName }}
    </div>
    <a
      v-if="profile.website"
      class="website"
      :href="profile.website"
      target="_blank"
    >
      <GlobeIcon />
      {{ profile.website }}
    </a>
    <div class="stats">
      <div>
        <span class="number">{{ profile.roundCount }}</span>
        {{ $t("profile.publications") }}
      </div>
      <div
        class="btn"
        @click="openFollowersModal"
      >
        <span class="number">{{ profile.followersCount }}</span>
        {{ $t("profile.followers") }}
      </div>
      <div
        class="btn"
        @click="openFollowingModal"
      >
        <span class="number">{{ profile.followingCount }}</span>
        {{ $t("profile.following") }}
      </div>
    </div>

    <UserListModal
      ref="followersModal"
      :title="$t('profile.followers')"
    />

    <UserListModal
      ref="followingModal"
      :title="$t('profile.following')"
    />
  </div>
</template>

<script>
import { autoLink } from 'vue-highlights';

import GlobeIcon from '@/components/icons/Globe.vue';
import MapPinIcon from '@/components/icons/MapPin.vue';

import highlightOptions from '@/components/highlightOptions';

import UserListModal from './UserListModal.vue';

import User from '@/models/User';

export default {
  components: {
    MapPinIcon,
    GlobeIcon,
    UserListModal,
  },
  props: {
    profile: {
      type: User,
      required: true,
    },
  },
  computed: {
    highlightedStatus() {
      return autoLink(this.profile.status, highlightOptions);
    },
  },
  methods: {
    async openFollowersModal() {
      this.$refs.followersModal.open(await this.profile.fetchFollowers());
    },
    async openFollowingModal() {
      this.$refs.followingModal.open(await this.profile.fetchFollowing());
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  margin-top: 24px;

  .status {
    margin-bottom: 8px;
    font: $font-rw-400;
  }

  .location, .website {
    font: $font-rw-400;
    color: $clr-text-secondary;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: max-content;

    svg {
      margin-right: 8px;
      color: $clr-text-trietary;
    }
  }

  .website {
    cursor: pointer;
    color: var(--clr-brand);
  }

  .stats {
    display: flex;

    > * {
      margin-right: 16px;
      color: $clr-text-secondary;
      font: $font-rw-400;

      .number {
        font: $font-sbw-400;
        color: $clr-text-primary;
      }
    }

    .btn:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .about {
    margin: 24px;
  }
}
</style>
