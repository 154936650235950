<template>
  <div>
    <label
      for="avatar-upload"
    >
      <img
        :src="avatar"
        class="avatar"
      >
      <div
        v-if="myProfile"
        class="hint"
      >
        {{ $t("profile.change_avatar") }}
      </div>
    </label>
    <input
      v-if="myProfile"
      id="avatar-upload"
      type="file"
      @change="previewAvatar"
    >
    <AvatarPreview ref="preview" />
  </div>
</template>

<script>
import AvatarPreview from './AvatarPreview.vue';

export default {
  components: {
    AvatarPreview,
  },
  props: {
    avatar: {
      type: String,
      default: '',
    },
    myProfile: {
      type: Boolean,
    },
  },
  methods: {
    previewAvatar(event) {
      const file = event.target.files[0];
      this.$refs.preview.open(file);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 16px;

  .hint {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .5s;
    background: rgba(var(--clr-brand), 30%);
    padding: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    color: white;

    &:hover {
      opacity: 1;
    }
  }

  .avatar {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    object-fit: cover;
    object-position: center;
  }
}

#avatar-upload {
  display: none;
}
</style>
