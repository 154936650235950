<template>
  <div
    @mouseover="playVid"
    @mouseleave="pauseVid"
  >
    <video
      v-if="desktop"
      ref="video"
      class="media"
      loop
      muted
      :poster="item.preview"
      preload="none"
    >
      <source :src="item.video">
    </video>
    <img
      v-else
      class="media"
      :src="item.preview"
    >
    <div class="icons">
      <CameraIcon />
      <ThreeDIcon v-if="item.hasThreeD" />
    </div>
  </div>
</template>

<script>
import CameraIcon from '@/components/icons/Camera.vue';
import ThreeDIcon from '@/components/icons/ThreeD.vue';

export default {
  components: {
    CameraIcon,
    ThreeDIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      desktop: undefined,
    };
  },
  mounted() {
    this.desktop = window.innerWidth > this.$scss.mobileBreakpoint;
  },
  methods: {
    playVid() {
      this.playPromise = this.$refs.video.play();
    },
    pauseVid() {
      if (this.playPromise !== undefined) {
        this.playPromise.then(() => {
          this.$refs.video.pause();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './media';
</style>
