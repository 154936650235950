<template>
  <Modal
    ref="modal"
  >
    <h1>
      {{ $t('profile.create_promocode') }}
    </h1>

    <input
      v-model="code"
      type="text"
      class="classic-input"
      :placeholder="$t('promocode')"
      :class="{ error }"
      @change="checkCode"
    >
    <div class="hint">
      {{ $t('profile.promocode_hint') }}
    </div>
    <button
      class="create btn primary"
      @click="create"
    >
      {{ $t('save') }}
    </button>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

import api from '@/api';

import Modal from '@/components/Modal.vue';

export default {
  emits: ['newCode'],
  components: {
    Modal,
  },
  data() {
    return {
      code: '',
      error: false,
    };
  },
  methods: {
    ...mapActions(['createInviteCode']),
    open() {
      this.$refs.modal.open();
    },
    async checkCode() {
      const { success } = await api.user.checkInviteCode({ value: this.code });
      console.log(success);
      this.error = !success;
    },
    async create() {
      const response = await this.createInviteCode(this.code);
      if (response.code) {
        this.error = true;
      } else {
        this.$emit('newCode');
        this.$refs.modal.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

input {
  border: 1px solid transparent;

  &.error {
    border-color: $clr-error;
  }
}

.hint {
  color: $clr-text-secondary;
  font: $font-rn-300;
  margin-top: 8px;
}

.create {
  margin: 32px auto 0;
}
</style>
