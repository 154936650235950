import { render, staticRenderFns } from "./CreatePromocodeModal.vue?vue&type=template&id=60398806&scoped=true&"
import script from "./CreatePromocodeModal.vue?vue&type=script&lang=js&"
export * from "./CreatePromocodeModal.vue?vue&type=script&lang=js&"
import style0 from "./CreatePromocodeModal.vue?vue&type=style&index=0&id=60398806&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60398806",
  null
  
)

export default component.exports