<template>
  <div class="whole-page">
    <div class="logo">
      <VerticalLogo />
    </div>
    <Sidebar class="sidebar-wrap" />
    <div
      class="edit-section with-sidebar"
    >
      <h1>{{ $t("profile.edit") }}</h1>

      <div
        v-if="profileUpdated"
        class="success-msg"
      >
        <CheckCircleIcon />
        {{ $t("profile.success_msg") }}
      </div>
      <div
        v-if="updateError"
        class="error-msg"
      >
        {{ $t("profile.error_msg") }}
      </div>

      <form
        @submit.prevent
      >
        <div class="field">
          <label for="name">
            {{ $t("profile.name") }}
          </label>
          <input
            id="first-name"
            v-model="$v.newProfile.name.$model"
            type="text"
            class="classic-input"
            :class="{
              invalid: $v.newProfile.name.$error
            }"
          >
          <div class="length-counter">
            <div
              class="number"
              :class="{
                invalid: !$v.newProfile.name.maxLength
              }"
            >
              {{ newProfile.name ? newProfile.name.length : 0 }}/40
            </div>
          </div>
        </div>

        <div class="field">
          <label for="sex">
            {{ $t("profile.sex") }}
          </label>
          <select
            id="sex"
            name="sex"
            @change="selectSex"
          >
            <option
              :value="1"
              :selected="selectedSex === 1"
            >
              {{ $t("profile.sex_not_set") }}
            </option>
            <option
              :value="2"
              :selected="selectedSex === 2"
            >
              {{ $t("profile.female") }}
            </option>
            <option
              :value="3"
              :selected="selectedSex === 3"
            >
              {{ $t("profile.male") }}
            </option>
          </select>
        </div>

        <div class="field">
          <label for="username">
            {{ $t("profile.username") }}
          </label>
          <input
            id="username"
            v-model="newProfile.username"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label for="status">
            {{ $t("profile.status") }}
          </label>
          <textarea
            id="status"
            v-model="newProfile.status"
            type="text"
          />
        </div>

        <div class="field">
          <label for="birthday-date">
            {{ $t("profile.birthday_date") }}
          </label>
          <input
            id="birthday-date"
            v-model="newProfile.birthday_date"
            v-maska="'##.##.####'"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label for="email">
            {{ $t("profile.email") }}
          </label>
          <input
            id="email"
            v-model="newProfile.email"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label for="phone">
            {{ $t("profile.phone") }}
          </label>
          <input
            id="phone"
            v-model="newProfile.phone"
            v-maska="'+###########'"
            type="text"
            class="classic-input"
          >
        </div>

        <div class="field">
          <label for="show-phone">
            {{ $t("profile.show_phone") }}
          </label>
          <label class="switch">
            <input
              id="show-phone"
              v-model="newProfile.show_phone"
              type="checkbox"
            >
            <span class="slider" />
          </label>
        </div>

        <div class="field">
          <label for="site">
            {{ $t("profile.website") }}
          </label>
          <input
            id="site"
            v-model="newProfile.site"
            type="text"
            class="classic-input"
            @change="formatWebsite"
          >
        </div>

        <div class="field">
          <label for="location-name">
            {{ $t("profile.location") }}
          </label>
          <input
            id="location-name"
            v-model="newProfile.location_name"
            type="text"
            class="classic-input"
          >
        </div>

        <button
          class="btn primary submit"
          :disabled="$v.newProfile.$invalid"
          @click="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';

import CheckCircleIcon from '@/components/icons/CheckCircle.vue';

import Sidebar from '@/components/TheSidebar/Index.vue';
import VerticalLogo from '@/components/VerticalLogo.vue';

export default {
  name: 'EditProfile',
  components: {
    Sidebar,
    CheckCircleIcon,
    VerticalLogo,
  },
  data() {
    return {
      newProfile: {},
      profileUpdated: false,
      updateError: false,
    };
  },
  validations: {
    newProfile: {
      name: {
        maxLength: maxLength(40),
      },
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.api.profile.profile,
    }),
    selectedSex() {
      if (this.newProfile.idc_sex) {
        return this.newProfile.idc_sex;
      }
      return this.profile.idc_sex;
    },
    canSubmit() {
      return Object.keys(this.newProfile).length > 0;
    },
  },
  mounted() {
    this.newProfile = {
      name: this.profile.name,
      idc_sex: this.profile.idc_sex,
      username: this.profile.username,
      status: this.profile.status,
      birthday_date: this.profile.birthday_date,
      email: this.profile.email,
      phone: this.profile.phone,
      show_phone: this.profile.show_phone,
      site: this.profile.site,
      location_name: this.profile.location_name,
    };
  },
  methods: {
    ...mapActions(['editProfile', 'emailCodeRequest']),
    selectSex(event) {
      this.$set(this.newProfile, 'idc_sex', Number(event.target.value));
    },
    async submit() {
      const success = await this.editProfile(this.newProfile);
      if (success) {
        this.updateError = false;
        this.profileUpdated = true;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        if (this.newProfile.email) {
          this.emailCodeRequest(this.newProfile.email);
        }
      } else {
        this.profileUpdated = false;
        this.updateError = true;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    formatWebsite() {
      if (!this.newProfile.site.startsWith('http://') && !this.newProfile.site.startsWith('https://')) {
        this.newProfile.site = `http://${this.newProfile.site}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whole-page {
  .logo {
    display: none;
  }

  .edit-section {
    h1 {
      font: $font-h-400;
      margin-bottom: 64px;
    }

    .success-msg, .error-msg {
      font: $font-rw-500;
      border-radius: 12px;
      padding: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    .success-msg {
      border: 2px solid var(--clr-brand);
      background: rgba(var(--clr-brand), 40%);
    }

    .error-msg {
      border: 2px solid $clr-error;
      background: rgba($clr-error, 40%);
    }

    .field {
      position: relative;
      margin: 16px 0;
      width: fit-content;

      label {
        display: block;
        font: $font-rn-400;
        margin-bottom: 8px;
      }

      input, textarea, select {
        width: 300px;
        border: 1px solid transparent;

        &.invalid {
          border: 1px solid $clr-error;
        }
      }

      .length-counter {
        position: absolute;
        right: 0;
        bottom: 14px;
        transform: translateX(calc(100% + 8px));

        .number {
          color: $clr-text-trietary;

          &.invalid {
            color: $clr-error;
            animation: shake 1s;
          }
        }
      }
    }

    .submit {
      margin-top: 32px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .sidebar-wrap {
    display: none;
  }

  .whole-page {
    padding-top: calc(env(safe-area-inset-top) + 24px);

    .logo {
      display: block;
      width: 100%;
      height: 64px;
      text-align: center;
      margin-bottom: 62px;

      img {
        height: 100%;
      }
    }

    .edit-section {
      h1 {
        margin-bottom: 32px;
      }

      .field {
        width: 100%;

        input, textarea, select {
          width: 100%;
        }
      }
    }
  }
}
</style>
