<template>
  <FeedWrapper
    :has-items="feed.length !== 0"
    :all-loaded="allLoaded"
  >
    <slot
      v-for="item in feed"
      v-bind="item"
    />
  </FeedWrapper>
</template>

<script>
import { debounce } from 'debounce';
import { mapActions } from 'vuex';

import FeedWrapper from './FeedWrapper.vue';

import roundFromBackend from '@/models/dto/roundFromBackend';

export default {
  components: {
    FeedWrapper,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      feed: [],
      loading: false,
      allLoaded: false,
    };
  },
  watch: {
    userId: {
      handler() {
        this.feed = [];
        this.loadFeed();
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('scroll', this.loadFeed);
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadFeed);
  },
  methods: {
    ...mapActions(['fetchUserRounds']),
    loadFeed: debounce(async function onScroll() {
      const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
      if (
        !this.loading
        && !this.allLoaded
        && distanceToBottom < window.innerHeight
      ) {
        this.loading = true;
        const newFeed = (await this.fetchUserRounds({
          userId: this.userId,
          offset: this.feed.length,
          longVideo: this.type === 'video',
        })).map((item) => roundFromBackend(item));

        this.feed = [...this.feed, ...newFeed];
        this.loading = false;

        if (newFeed.length === 0) this.allLoaded = true;
      }
    }, 100),
  },
};
</script>
