<template>
  <div class="whole-page">
    <div class="logo-wrapper">
      <VerticalLogo />
    </div>
    <Sidebar class="sidebar-wrap" />
    <div
      v-if="profile && !blocked"
      class="profile"
    >
      <div
        v-if="ownProfile"
        class="show-qr"
        @click="showQr"
      >
        <QrCodeIcon />
      </div>
      <router-link
        v-if="ownProfile"
        class="open-notifs"
        :to="{ name: 'Notifications' }"
      >
        <BellIcon />
      </router-link>
      <div
        v-if="!ownProfile"
        class="back-arrow"
        @click="$router.back();"
      >
        <ArrowIcon />
      </div>
      <div
        v-if="ownProfile"
        class="more-dots mobile-only"
        @click="logout"
      >
        <LogoutIcon class="logout" />
      </div>
      <div
        v-else
        class="more-dots"
        @click="toggleMore"
      >
        <MoreIcon />
        <Popover class="popover">
          <ProfileActions
            :blacklisted="profile.blacklisted"
            @block="block"
            @unBlock="unBlock"
            @showQr="showQr"
            @report="report"
          />
        </Popover>
      </div>

      <Modal
        ref="more"
        class="more mobile-only"
      >
        <h1>{{ $t("profile.actions") }}</h1>
        <ProfileActions
          :blacklisted="profile.blacklisted"
          @block="block"
          @unBlock="unBlock"
          @showQr="showQr"
          @report="report"
        />
      </Modal>

      <Modal ref="qr">
        <QrCode
          class="qr"
          :uri="profile.deepLink"
        />
      </Modal>

      <ReportModal
        :id="profile.id"
        ref="report"
        target="user"
      />

      <Person
        class="person"
        :profile="profile"
      />

      <About
        class="about"
        :profile="profile"
      />

      <ProfileFeed
        v-if="!profile.blacklisted"
        :id="Number(profile.id)"
      />
      <div
        v-else
        class="blocked"
      >
        {{ $t("profile.blocked") }}
      </div>
    </div>
    <div
      v-else-if="blocked"
      class="profile"
    >
      <div class="blocked">
        {{ $t("profile.blocked_you") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import api from '@/api';

import ArrowIcon from '@/components/icons/Arrow.vue';
import BellIcon from '@/components/icons/Bell.vue';
import LogoutIcon from '@/components/icons/Logout.vue';
import MoreIcon from '@/components/icons/More.vue';
import QrCodeIcon from '@/components/icons/QrCode.vue';

import Modal from '@/components/Modal.vue';
import Popover from '@/components/Popover.vue';
import QrCode from '@/components/QrCode.vue';
import ReportModal from '@/components/ReportModal.vue';
import Sidebar from '@/components/TheSidebar/Index.vue';
import VerticalLogo from '@/components/VerticalLogo.vue';

import About from './About.vue';
import Person from './Person/Index.vue';
import ProfileActions from './ProfileActions.vue';
import ProfileFeed from './ProfileFeed/Index.vue';

import userFromBackend from '@/models/dto/userFromBackend';

export default {
  name: 'Profile',
  components: {
    Sidebar,
    ProfileFeed,
    MoreIcon,
    LogoutIcon,
    QrCodeIcon,
    BellIcon,
    QrCode,
    Modal,
    Popover,
    ProfileActions,
    ReportModal,
    Person,
    About,
    ArrowIcon,
    VerticalLogo,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default() {
        return this.$store.getters.profile.id;
      },
    },
  },
  data() {
    return {
      profile: undefined,
      blocked: false,
      mobile: false,
    };
  },
  computed: {
    ...mapGetters({
      stateProfile: 'profile',
    }),
    ownProfile() {
      return this.profile.id === this.stateProfile.id;
    },
  },
  watch: {
    $route() {
      this.getProfile();
    },
    stateProfile(newProfile) {
      if (this.ownProfile) {
        this.profile = newProfile;
      }
    },
  },
  mounted() {
    this.getProfile();
    this.mobile = window.innerWidth <= this.$scss.mobileBreakpoint;
  },
  methods: {
    async getProfile() {
      function isNumeric(num) {
        return !isNaN(num); // eslint-disable-line
      }

      let id;
      let username;

      if (isNumeric(this.id)) {
        id = this.id;
      } else {
        username = this.id;
      }

      const response = await api.user.fetchProfile({ id, username });

      if (response.code === 20) {
        this.blocked = true;
      } else {
        this.profile = userFromBackend(response.profile);
      }
    },
    toggleMore() {
      if (this.mobile) {
        this.$refs.more.open();
      }
    },
    async block() {
      const result = await this.$store.dispatch('block', this.id);
      if (result.status === 200) {
        this.profile.blacklisted = true;
      }
    },
    async unBlock() {
      const result = await this.$store.dispatch('unBlock', this.id);
      if (result.status === 200) {
        this.profile.blacklisted = false;
      }
    },
    showQr() {
      this.$refs.more.close();
      this.$refs.qr.open();
    },
    async report() {
      this.profile.blacklisted = true;
      this.$refs.report.open();
    },
    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'Home', params: { type: 'rounds' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.whole-page {
  .logo-wrapper {
    display: none;
  }

  .profile {
    position: relative;
    max-width: 732px;
    margin-left: 40%;

    .more-dots, .show-qr, .back-arrow, .open-notifs {
      position: absolute;
      top: 0;
      right: 0;
      background: white;
      border: 1px solid $clr-border-light;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;

      svg {
        color: $clr-text-secondary
      }
    }

    .show-qr, .back-arrow {
      cursor: pointer;
    }

    .open-notifs {
      display: none;
    }

    .more-dots {
      .popover {
        z-index: 10;
      }

      &:hover {
        .popover {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .mobile-only {
      display: none;
    }

    .blocked {
      color: $clr-text-trietary;
      width: fit-content;
      margin: 48px auto;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .whole-page {
    padding-top: calc(env(safe-area-inset-top) + 24px);

    .logo-wrapper {
      display: block;
      width: 100%;
      margin-bottom: 62px;

      .logo {
        margin: auto;
      }
    }

    .profile {
      position: static;
      margin: auto;

      .more.mobile-only {
        display: block;

        .qr {
          padding: 32px 32px 0;
        }

        h1 {
          font: $font-h-200;
        }
      }

      .more-dots, .show-qr, .back-arrow {
        top: calc(env(safe-area-inset-top) + 30px);
      }

      .show-qr, .back-arrow, .open-notifs {
        left: 30px;
      }

      .open-notifs {
        display: flex;
        top: calc(env(safe-area-inset-top) + 68px);
      }

      .more-dots {
        right: 30px;
        display: flex;

        .logout {
          color: $clr-text-secondary;
        }

        .popover {
          display: none;
        }

        &:hover {
          .popover {
            display: none;
          }
        }
      }
    }
  }
}
</style>
