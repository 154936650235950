<template>
  <div>
    <img
      class="media"
      :src="item.preview"
    >
    <div class="icons">
      <PhotoIcon />
      <ThreeDIcon v-if="item.hasThreeD" />
    </div>
  </div>
</template>

<script>
import PhotoIcon from '@/components/icons/Photo.vue';
import ThreeDIcon from '@/components/icons/ThreeD.vue';

export default {
  components: {
    PhotoIcon,
    ThreeDIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './media';
</style>
