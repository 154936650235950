<template>
  <div class="wrapper">
    <Categories @selectCategory="setCategory" />
    <component
      :is="feedComponent"
      :user-id="id"
    />
  </div>
</template>

<script>
import Categories from './Categories.vue';
import ChannelFeed from './ChannelFeed/Index.vue';
import PostFeed from './PostFeed.vue';
import VideoFeed from './VideoFeed.vue';

export default {
  name: 'ProfileFeed',
  components: {
    Categories,
    PostFeed,
    VideoFeed,
    ChannelFeed,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      feedComponent: 'PostFeed',
    };
  },
  methods: {
    setCategory(feedComponent) {
      this.feedComponent = feedComponent;
    },
  },
};
</script>
